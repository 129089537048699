// GA Universal start
export interface IGAEvent {
  event?: string;
  category: string;
  action: string;
  label: string;
  value?: string;
  worthyBuyerId?: number | string;
  auctionId?: number | string;
}

export enum Labels {
  watch = 'Watched auctions',
  all = 'All',
  diamonds = 'Diamonds',
  jewelry = 'Jewelry',
  watches = 'Watches',
  in_prog = 'In Progress',
  won = 'Won',
  didnt_win = "Didn't win",
  auction = 'Auction',
}

export enum Actions {
  AuctionPreviewOpened = 'Auction Preview Opened',
  AuctionClicked = 'Auction Clicked',
  FiltersOpened = 'Filters Opened',
  FiltersClosed = 'Filters Closed',
  ClearFiltersClicked = 'Clear Filters Clicked',
  CertificateClicked = 'Certificate Clicked',
  MediaImagesClicked = 'Media - images Clicked',
  Media360Clicked = 'Media - 360 Clicked',
  FiltersEmpty = 'Filters Empty state reached',
  FilterClicked = 'Auctions Filter Clicked',
  SortClicked = 'Auctions Sort Clicked',
  UpdateBid = 'Update Bid Clicked',
}
// GA Universal end

export enum GA4Pages {
  items = 'auctionsActive',
  my_auctions = 'auctionsHistory',
  auction = 'auctionPage',
  tenders = 'tenders',
}

export enum GA4Sections {
  all = 'all',
  diamonds = 'diamonds',
  jewelry = 'jewelry',
  watches = 'watches',
  in_prog = 'inProgress',
  won = 'won',
  didnt_win = 'didntWin',
  watch = 'watchedAuctions',
  auction = 'auction',
  history = 'history',
}

export enum GA4Events {
  Interaction = 'interaction',
  Feedback = 'feedback',
}

export enum GA4Actions {
  // feedback actions
  Open = 'open',
  Success = 'success',
  Failure = 'failure',
  Close = 'close',
  // interaction actions
  Select = 'select',
  Clear = 'clear',
  Focus = 'focus',
  Input = 'input',
  Submit = 'submit',
  Toggle = 'toggle',
  Navigate = 'navigate',
  Sort = 'sort',

  // we should try to avoid using this action
  Click = 'click',
}

// all fields and values should be in camelCase
export interface IGA4Event {
  eventName: GA4Events;
  location1: 'buyersPortal';
  location2: GA4Pages;
  location3?: GA4Sections;
  location4?: string;
  location5?: string;
  action: GA4Actions;
  actionedObject?: string; // item that triggered the event (button, filter)
  actionedValue?: string;
  actionedObject2?: string;
  actionedValue2?: string;
  actionedObject3?: string;
  actionedValue3?: string;
  actionedObject4?: string;
  actionedValue4?: string;
  actionedObject5?: string;
  actionedValue5?: string;
  fieldName?: string;
  fieldValue?: string;
  appVersion: string;
  buyerId: number | undefined;
  topic?: string;
  // sessionId: string; - it is auto generated by GA
}

export type IGA4EventData = {
  action: IGA4Event['action'];
  actionedObject?: IGA4Event['actionedObject'];
  actionedValue?: IGA4Event['actionedValue'];
  actionedObject2?: IGA4Event['actionedObject2'];
  actionedValue2?: IGA4Event['actionedValue2'];
  actionedObject3?: IGA4Event['actionedObject3'];
  actionedValue3?: IGA4Event['actionedValue3'];
  actionedObject4?: IGA4Event['actionedObject4'];
  actionedValue4?: IGA4Event['actionedValue4'];
  actionedObject5?: IGA4Event['actionedObject5'];
  actionedValue5?: IGA4Event['actionedValue5'];
  fieldName?: IGA4Event['fieldName'];
  fieldValue?: IGA4Event['fieldValue'];
  eventName?: IGA4Event['eventName'];
  location2?: IGA4Event['location2'];
  location3?: IGA4Event['location3'];
  location4?: IGA4Event['location4'];
  location5?: IGA4Event['location5'];
  topic?: IGA4Event['topic'];
};
