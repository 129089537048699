import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_MONOLITH_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use((response) => {
  if (!response.request.responseURL.includes(response.config.url)) {
    window.location = response.request.responseURL;
    return;
  }

  return response;
});
