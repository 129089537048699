import Rollbar from 'rollbar';

if (process.env.REACT_APP_ENV !== 'local') {
  window.rollbar = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_ENV,
    },
    checkIgnore: (isUncaught, args, payload: any) => payload.environment === 'local',
  });
}
