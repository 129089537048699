import { lazy } from 'react';
import { createRoot } from 'react-dom/client';

import 'react-loading-skeleton/dist/skeleton.css';
import 'services/api/setup';
import 'services/ga/gaEventManager';
import 'services/rollbar';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import 'polyfills';

function render() {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  const url = new URL(window.location.href);

  if (url.pathname.includes('/buyers') || window.location.origin !== process.env.PUBLIC_URL) {
    const Views = lazy(() => import('./Views'));
    root.render(<Views />);
    return;
  }

  const App = lazy(() => import('./App'));

  root.render(<App />);
}

render();
