import { GA4Pages, GA4Sections } from './interfaces';

export const getLocation = () => {
  const { pathname } = window.location;

  const primaryOptions = Object.keys(GA4Pages).join('|');
  const secondaryOptions = Object.keys(GA4Sections).join('|');
  const regex = new RegExp(`(${primaryOptions})|(${secondaryOptions}|\\d+)`, 'gi');
  const locationMatch = pathname.match(regex);

  if (!locationMatch || !locationMatch[0] || !locationMatch[1]) {
    return {
      locationPrimary: 'Unknown',
      locationSecondary: 'Unknown',
    };
  }

  const locationPrimary =
    locationMatch[0] in GA4Pages
      ? GA4Pages[locationMatch[0] as keyof typeof GA4Pages]
      : locationMatch[0];
  const locationSecondary =
    locationMatch[1] in GA4Sections
      ? GA4Sections[locationMatch[1] as keyof typeof GA4Sections]
      : undefined;

  return {
    locationPrimary,
    locationSecondary,
  };
};

export const ga4NullEventFields = {
  eventName: null,
  location1: null,
  location2: null,
  location3: null,
  location4: null,
  location5: null,
  action: null,
  actionedObject: null,
  actionedValue: null,
  actionedObject2: null,
  actionedValue2: null,
  actionedObject3: null,
  actionedValue3: null,
  actionedObject4: null,
  actionedValue4: null,
  actionedObject5: null,
  actionedValue5: null,
  fieldName: null,
  fieldValue: null,
  topic: null,
};
